import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      title: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.video', route: { name: 'video' } },
      ],
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PAGE']),
      listRoute: () => ({ name: 'video' }),
      afterCreateRoute: result => ({
        name: 'video-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.video',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            title: {
              required: true,
              label: 'data.title',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            code: {
              required: true,
              label: 'data.code',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            keywords: {
              label: 'data.keywords',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photo: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'video',
              photoManager: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            brief: {
              label: 'page.data.brief',
              type: 'textarea',
              grid: { xl: 12, lg: 12, md: 12 },
              maxlength: 300,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            content: {
              label: 'data.content',
              type: 'html',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        // 影片設定
        {
          title: 'video.form_block.video',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            youtube_url: {
              label: 'video.data.youtube_url',
              grid: { xl: 12, lg: 12, md: 12 },
              create: (formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return false
                // @ts-ignore
                return formInfo.formData.type === this.vm.$videoConstants.TYPE_YOUTUBE
              },
              maxlength: 150,
              thumbnail: (formInfo: FormInfoInterface, data: any) => {
                const youtubeUid = this.vm?.$helper.getYoutubeUid(data)
                return this.vm?.$helper.getYoutubeImage(youtubeUid)
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
